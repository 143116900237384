
export const projects = [
    {
        title: "nairolf32",
        subtitle: "My personal website",
        description:
            "My website. A piece of art. A mastery of design. All in french",
        image: "https://nairolf32.com/assets/img/self.png",
        link: "https://nairolf32.com",
    },
    {
        title: "Blog",
        subtitle: "my blog",
        description:
            "A personal blog about many things, mostly tech with links to my other blogs",
        image: "https://blog.nairolf32.com/images/blog-logo.png",
        link: "https://blog.nairolf32.com/",
    },
    {
        title: "Github index",
        subtitle: "My github page",
        description:
            "My very minimalistic github page",
        image: "https://nair0lf32.github.io/assets/img/avataaars.svg",
        link: "https://nair0lf32.github.io/",
    },
    {
        title: "Medical Cheatsheets (MCS)",
        subtitle: "medical pathologies articles",
        description:
            "A simple jekyll blog with articles about medical pathologies. the blog is written in french",
        image: "https://nair0lf32.github.io/mcs/assets/images/MCS.png?v=",
        link: "https://nair0lf32.github.io/mcs/",
    },
];


export const skills = [
    {
        name: "Linux",
        logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linux/linux-original.svg",
    },
    {
        name: "Javascript",
        logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
    },
    {
        name: "React Js",
        logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
    },
    {
        name: "Python",
        logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg",
    },
    {
        name: "Php",
        logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-original.svg",
    },
    {
        name: "Golang",
        logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/go/go-original.svg",
    },
    {
        name: "Java",
        logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg",
    },
    {
        name: "Flutter",
        logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flutter/flutter-original.svg",
    },
];

